import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  Container,
  ContractMapping,
  ErrorHandling,
  FieldList,
  FrequencyMapping,
  GeneralInfoService,
  getLanguage,
  Panel,
  Permission,
  PolicyInfo,
  PremiumStatusMapping,
  RBAC,
  TableField,
  useTranslator,
  Locale,
  assets,
  AuthState
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { WarningPopup, IWarningData } from '../../common'

// const columns = [
//   {
//     key: '1',
//     title: 'No.',
//     name: 'stt'
//   },
//   {
//     key: '2',
//     title: 'Beneficiary Name',
//     name: 'name'
//   },
//   {
//     key: '3',
//     title: 'Percentage',
//     name: 'percent'
//   },
//   {
//     key: '4',
//     title: 'Relationship',
//     name: 'relationShipCode'
//   }
// ]

export interface PolicyInfoData {
  policyInfo: PolicyInfo | null
  isPending: boolean
  isInProgress: boolean
  isSpecialNote: boolean
  dataSource: TableField.DataSources
}

export const PolicyInfoScreen = (props: {
  id: string
  data: PolicyInfoData | null
  setData: (val: PolicyInfoData | null) => void
  isGeneral: boolean
}) => {
  const { setData } = props
  const [loading, bindLoading] = useLoading()
  const { i18n } = useTranslation()
  const { t } = useTranslator('TaskManagement')
  const { t: tCommon } = useTranslator('common')
  const m = useTranslator('message').t
  const ct = useTranslator('contract').t
  const pst = useTranslator('premiumStatus').t
  const ft = useTranslator('frequency').t

  const userLogin = pipe(AuthState.auth.get, ErrorHandling.runDidMount())

  const [openDetail, setOpenDetail] = useState<boolean>(false)

  const getData = (): ZIO<unknown, Throwable, PolicyInfoData | null> =>
    pipe(
      GeneralInfoService.getPolicyInfo(props.id, i18n.language as Locale),
      ZIO.foldM(
        (_) => ZIO.succeed(null),
        (_policyInfo) =>
          ZIO.effect(() => {
            return {
              policyInfo: _policyInfo,
              isPending: _policyInfo.statuses?.some((s) => s === 'PENDING'),
              isInProgress: _policyInfo.statuses?.some((s) => s === 'IN_PROCESS'),
              isSpecialNote: false,
              dataSource: _policyInfo.bensData?.map((b, i) => ({
                stt: (i + 1).toString(),
                name: b.name,
                percent: b.percentage + '%',
                relationShipCode: b.relationship ?? '-'
              }))
            }
          })
      ),
      ZIO.flatMap((res) =>
        pipe(
          RBAC.permissions,
          ZIO.map((x) => {
            return res
              ? {
                ...res,
                isSpecialNote:
                  (props.isGeneral && x.includes(Permission.ViewGeneralInfoSpecialNotesTaskDetail)) ||
                  (!props.isGeneral && x.includes(Permission.ViewSpecialNotesPolicyDetail))
              }
              : null
          })
        )
      )
    )

  const { policyInfo, isPending, isInProgress, isSpecialNote, dataSource } = pipe(
    props.data !== null ? ZIO.succeed(props.data) : getData(),
    ZIO.tap((x) => {
      if (x !== null && props.data === null) setData(x)
      return ZIO.unit
    }),
    bindLoading,
    ErrorHandling.runDidMount()
  ) || {
    policyInfo: null,
    isPending: false,
    isInProgress: false,
    isSpecialNote: false,
    dataSource: []
  }

  const isCheckPolicyMessage = pipe(
    GeneralInfoService.checkPolicyMessage(props.id ?? ''),
    bindLoading,
    ErrorHandling.runDidUpdate([props.id ?? ''])
  )

  const customerReceiveMethod = policyInfo?.customerReceiveMethod === 'ePolicy' ? t('eKit') : t('hardCopy')

  const policyInfoList = [
    {
      label: tCommon('PolicyOwner'),
      value: policyInfo?.policyOwner
    },
    {
      label: t('MainLifeAssured'),
      value: policyInfo?.mainLA
    },
    {
      label: t('APLDate'),
      value: policyInfo?.APLDate
    },
    {
      label: t('ContractName'),
      value: getLanguage() === 'en' ? policyInfo?.contractNameRaw?.en || '' : policyInfo?.contractNameRaw?.vi || ''
    },
    {
      label: t('PolicyStatus'),
      value: ContractMapping.get(ct)(policyInfo?.policyStatus)
    },
    {
      label: t('PremiumStatus'),
      value: PremiumStatusMapping.get(pst)(policyInfo?.premiumStatus)
    },
    {
      label: t('SumAssured'),
      value: policyInfo?.sumAssured
    },
    {
      label: t('InstallmentPremium'),
      value: policyInfo?.installmentPremium
    },
    {
      label: t('Frequency'),
      value: FrequencyMapping.get(ft)(policyInfo?.frequency)
    },
    {
      label: t('1stIssueDate'),
      value: policyInfo?.firstIssuedDate
    },
    {
      label: t('PolicyIssueDate'),
      value: policyInfo?.policyIssuedDate
    },
    {
      label: t('RiskCommencementDate'),
      value: policyInfo?.riskCommenceDate
    },
    {
      label: t('PaidToDateBasic'),
      value: policyInfo?.paidToDateBasic
    },
    {
      label: t('PremiumCessationDate'),
      value: policyInfo?.premiumCessDate
    },
    {
      label: t('RiskCessationDate'),
      value: policyInfo?.riskCessDate
    },
    {
      label: t('CustomerReceiveDate'),
      value: policyInfo?.customerReceiveDate
    },
    {
      label: t('CustomerReceiveMethod'),
      value: customerReceiveMethod
    }
  ]

  const columns = [
    {
      key: '1',
      title: tCommon('No.'),
      name: 'stt'
    },
    {
      key: '2',
      title: t('BeneficiaryName'),
      name: 'name'
    },
    {
      key: '3',
      title: t('Percentage'),
      name: 'percent'
    },
    {
      key: '4',
      title: t('Relationship'),
      name: 'relationShipCode'
    }
  ]

  const handlePressDetail = () => {
    setOpenDetail(true)
  }
  return (
    <Container loading={loading}>
      <View style={styles.sectionStatus}>
        {isPending && (
          <View style={styles.sectionStatusTag}>
            <Text style={styles.sectionStatusTagText}>{m('MS050250')}</Text>
          </View>
        )}
        {isInProgress && (
          <View style={styles.sectionStatusTag}>
            <Text style={styles.sectionStatusTagText}>{m('MS050251')}</Text>
          </View>
        )}

        {isCheckPolicyMessage?.showMessage && (
          <View style={styles.sectionStatusTag}>
            <Text style={styles.sectionStatusTagText}>{isCheckPolicyMessage.messageCode === "" ? m('MS050057') : isCheckPolicyMessage.message}</Text>
          </View>
        )}

        {policyInfo?.hasSpecialNote && isSpecialNote && (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.blueText}>{t('SpecialNote')}</Text>
            {userLogin?.tag !== AuthState.Status.GA && (
              <>
                <assets.RedEye />
                <Text style={[styles.dangerText, styles.strongText]} onPress={handlePressDetail}>
                  {t('Detail')}
                </Text>
              </>
            )}
          </View>
        )}
      </View>
      {!!policyInfo && (
        <Panel title={tCommon('Policy')}>
          <FieldList dataSource={policyInfoList ?? []} />
        </Panel>
      )}
      {dataSource?.length > 0 && (
        <Panel title={t('beneficiaryInfo')}>
          <TableField columns={columns} dataSource={dataSource} name="beneficiaryInfo" currency="" />
        </Panel>
      )}

      <WarningPopup
        visible={openDetail}
        dataSource={(policyInfo?.warningList as IWarningData[]) ?? []}
        onClose={() => setOpenDetail(false)}
      />
    </Container>
  )
}

const styles = StyleSheet.create({
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 30
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  blueText: {
    color: '#1EA5FC',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  }
})
