import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
  TouchableOpacity,
  Pressable,
  TextStyle,
  ScrollView,
  FlatList,
  Image,
  TextInput
} from 'react-native'
import { pipe } from 'fp-ts/lib/function'
import { useLoading } from '@mxt/zio-react'
import { Throwable, ZIO } from '@mxt/zio'
import {
  Container,
  ErrorHandling,
  FieldList,
  Panel,
  useTranslator,
  PulseOpsFormat,
  SelectTable,
  TaskDetail,
  form2,
  GeneralService,
  AppContext,
  assets,
  InputTable,
  ModalComponent,
  ModalAction,
  Input,
  getLanguage
} from '@pulseops/common'
import { useTranslation } from 'react-i18next'
import { AudioRef, DisplayTrack, SC } from './components'
import Controls from './components/Controls'
import ProgressBar from './components/ProgressBar'
import './components/customize-progress-bar.css'
import './components/index.css'
import { VoiceRecordConsumer, useVoiceRecording } from './BussinessLogic'
import { Controller, useFieldArray } from 'react-hook-form'
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  makeStyles,
  Table,
  Button,
  LinearProgress,
  CircularProgress,
  Tooltip,
  withStyles
} from '@material-ui/core'
import { VoiceRecordingServices } from './VoiceRecordingServices'
import { VoiceRecordingModels } from './VoiceRecordingModels'
import _, { isArray, isEmpty, update } from 'lodash'
import { VoiceDataGridForm } from './VoiceRecordingForm'
import { VoiceRecordingConst } from './VoiceRecordingConst'
import { FieldText, SectionCol, SectionContent, SectionRow, Error, Column } from '@pulseops/submission/common'
import { useForm } from 'react-hook-form'
import { channel } from 'diagnostics_channel'
interface IVoiceData {
  voiceRecord: Array<VoiceRecordingModels.ResponseVoiceList>
  officeCodeRes: {
    code: string
    nameEn: string
    nameVi: string
    type: string
    status: string
    bankCode: string | null | undefined
    gaCode: string | null | undefined
  } | null
  // dynamicKeyword: Array<VoiceRecordingModels.DynamicKeywordC>
}
export interface VoiceRecordingcreenProps {
  detail: TaskDetail.Voice_Recording
  processId: string
  isHistory: boolean | null
  isInquiry: boolean | null
  isSuspend: boolean | null
  isQC: boolean | null
  policyStatus: string
  playerPosition: number
  setDynamicKeywordData?: (data: Array<VoiceDataGridForm.DynamicKeywordData>) => void
  setIsSavedDynamicKeyword?: (value: boolean) => void
}
export const VoiceRecordingScreen = ({
  detail,
  processId,
  isInquiry,
  isHistory,
  isSuspend,
  isQC,
  policyStatus,
  playerPosition,
  setDynamicKeywordData,
  setIsSavedDynamicKeyword
}: VoiceRecordingcreenProps) => {
  const [loading, bindLoading] = useLoading()
  const { i18n } = useTranslation()
  const { t } = useTranslator('TaskManagement')
  const { t: tCommon } = useTranslator('VoiceRecording')
  const { t: m } = useTranslator('message')
  const { height, width } = useWindowDimensions()

  const { state, controller } = useVoiceRecording()
  const useStyles = makeStyles({
    container: {
      border: `1px solid #D3DCE6`,
      borderWidth: 1,
      borderRadius: 8,
      maxHeight: height - 340
    },
    table: {},
    tableHeader: {
      backgroundColor: '#E7E7E7'
    },
    tableBody: {
      backgroundColor: '#FFFFFF'
    },
    tableCellHeader: {
      backgroundColor: '#F4F4F4',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      color: '#70777E',
      verticalAlign: 'top'
    },
    tableCell: {
      whiteSpace: 'nowrap'
    }
  })

  const classes = useStyles()

  const audioRef = useRef()
  const progressBarRef = useRef()
  const refTrack = React.useRef<AudioRef | null>(null)
  const [timeProgress, setTimeProgress] = useState<number>(0)
  const [activeScript, setActiveScript] = useState<number | null>(null)
  // const [loadingSrc, setLoadingSrc] = useState<boolean>(false)
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false)
  const [timeEndProgress, setTimeEndProgress] = useState<number | null>(null)
  const [keyWordGroupInfo, setKeyWordGroupInfo] = useState<{
    agentScoredPassed: number
    maxAgentScore: number
    customerScoredPassed: number
    maxCustomerScore: number
  }>({
    agentScoredPassed: 0,
    maxAgentScore: 0,
    customerScoredPassed: 0,
    maxCustomerScore: 0
  })

  const [keyWordInfo, setKeyWordInfo] = useState<{
    agentScoredPassed: number
    maxAgentScore: number
    customerScoredPassed: number
    maxCustomerScore: number
  }>({
    agentScoredPassed: 0,
    maxAgentScore: 0,
    customerScoredPassed: 0,
    maxCustomerScore: 0
  })

  const [speechAccents, setSpeechAccents] = useState<{
    finalWarningMatching: boolean
    warningAgent: boolean
    warningCustomer: boolean
  }>({
    finalWarningMatching: false,
    warningAgent: false,
    warningCustomer: false
  })

  const [speechData, setSpeechData] = React.useState<VoiceDataGridForm.DynamicKeywordData[]>([])
  const [filteredDataTable, setFilteredDataTable] = React.useState<VoiceDataGridForm.DynamicKeywordData[]>([])
  const [activeSpeaker, setActiveSpeaker] = React.useState<number | null>(null)

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgb(112, 119, 126)',
      fontSize: 15
    }
  }))(Tooltip)

  const { showToast } = React.useContext(AppContext.AppContextInstance)
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    reset,
    register,
    unregister
  } = useForm<VoiceDataGridForm.Validated>({
    defaultValues: {
      dynamicKeyword: [],
      owner: '',
      keyword: '',
      worthKeyword: '',
      result: ''
    },
    mode: 'onChange'
  })
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'dynamicKeyword'
  })

  const getData = (): ZIO<unknown, Throwable, IVoiceData> =>
    pipe(
      ZIO.zip(VoiceRecordingServices.getVoiceData(processId), GeneralService.getOfficeCode(detail.officeCode ?? '')),
      ZIO.map(([res, officeCode]) => {
        const { body } = res

        const data = body.sort((a, b) => {
          const aDateTime = a?.recordDateTime ? new Date(a.recordDateTime) : new Date(0)
          const bDateTime = b?.recordDateTime ? new Date(b.recordDateTime) : new Date(0)
          return bDateTime.valueOf() - aDateTime.valueOf()
        })

        return {
          voiceRecord: data,
          officeCodeRes: detail.officeCode != null ? officeCode.body?.[0] : null
        }
      })
    )
  function formatDuration(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000)
    const minutes = Math.floor(totalSeconds / 60)
    const seconds = totalSeconds % 60

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
  const { voiceRecord, officeCodeRes } = pipe(getData(), bindLoading, ErrorHandling.runDidMount()) || {
    voiceRecord: []
  }

  const policyInfoList = [
    {
      label: tCommon('ProposalNum'),
      value: detail.proposalNumber || '-'
    },
    {
      label: tCommon('PolicyStatus'),
      value: policyStatus || detail.policyStatus || '-'
    },
    {
      label: tCommon('AgentViolation'),
      value: detail.blackListAgent ? tCommon('Yes') : tCommon('No')
    },
    {
      label: tCommon('ServiceAgent'),
      value: detail.agentCode + ' - ' + detail.agentName
    },
    {
      label: tCommon('SalesChannel'),
      value: detail.saleChannel || '-'
    },
    {
      label: tCommon('HeadOfficeAgent'),
      value: !isEmpty(officeCodeRes)
        ? (officeCodeRes?.code ?? ' ') +
            ' - ' +
            (i18n.language === 'vi' ? officeCodeRes?.nameVi : officeCodeRes?.nameEn) || '-'
        : detail.officeCode
        ? detail.officeCode
        : '-'
    },
    {
      label: tCommon('Decision'),
      value: detail.verificationDecision || '-'
    }
  ]
  const [generalFields, setGeneralFields] = useState(policyInfoList)
  const generateSTT = (number: string) => {
    switch (number) {
      case '1':
        return 'st'
      case '2':
        return 'nd'
      case '3':
        return 'rd'
      default:
        return 'th'
        break
    }
  }
  useEffect(() => {
    switch (detail.poRole) {
      case 'FEE_NOT_SHARE':
        const feeNotShare = [
          {
            label: tCommon('DeclarationSigningDate'),
            value: PulseOpsFormat.dateStringtoFormat(detail.signDateCompany, 'DD/MM/YYYY') || '-'
          },
          {
            label: tCommon('EmployeesParticipation'),
            value: (detail.numberOfInsured as unknown as string) || '0'
          },
          {
            label: tCommon('LicenseMST'),
            value: detail.taxNumber || '-'
          },
          {
            label: tCommon('BusinessLicense'),
            value: detail.agentCertificate || '-'
          }
          // {
          //   label: '',
          //   // @ts-ignore
          //   value: (
          //     <View style={{ display: 'flex', flexDirection: 'row' }}>
          //       <assets.WarningIconVoice />
          //       <Text style={{ paddingLeft: 5 }}>
          //         {tCommon('FileRecord')}{detail.parentPolicyNumber}, {tCommon('ClientNumber')}
          //         {` ${detail.clientNumber}`}, {getLanguage() === 'vi' ? tCommon('Proposal') : ''}
          //         {getLanguage() === 'vi'
          //           ? " " +(detail.sequencePolicy ?? 0)
          //           : detail.sequencePolicy + '' + generateSTT(detail.sequencePolicy ?? '1')}
          //         {detail.totalPolicy ? `/${detail.totalPolicy}` : ''}
          //       </Text>
          //     </View>
          //   ),
          //   colLength: '100%'
          // }
        ]
        // @ts-ignore
        setGeneralFields(generalFields.concat(feeNotShare))
        break
      case 'FEE_SHARE':
        const feeShare = [
          {
            label: '',
            value: ' '
          },
          {
            label: '',
            value: ' '
          },
          {
            label: tCommon('LicenseMST'),
            value: detail.taxNumber || '-'
          },
          {
            label: tCommon('BusinessLicense'),
            value: detail.agentCertificate || '-'
          },
          {
            label: '',
            // @ts-ignore
            value: (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <assets.WarningIconVoice />
                <Text style={{ paddingLeft: 5 }}>
                  {tCommon('SameClient')} {detail.clientNumber}
                </Text>
              </View>
            ),
            colLength: '100%'
          }
        ]
        // @ts-ignore
        setGeneralFields(generalFields.concat(feeShare))
        break

      default:
        break
    }
  }, [detail.poRole])

  useEffect(() => {
    if (detail.poRole === 'FEE_NOT_SHARE' && voiceRecord.length) {
      if (detail.policyNumber !== detail.parentPolicyNumber && voiceRecord.length === 1) {
        const feeNotShare = [
          {
            label: '',
            // File ghi âm được lấy từ HSYCBH số <proposal num>, là hồ sơ thứ 1/n
            // @ts-ignore
            value: (
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <assets.WarningIconVoice />
                <Text style={{ paddingLeft: 5 }}>
                  {tCommon('FileRecord')}
                  {detail.parentProposalNumber}, {getLanguage() === 'vi' ? tCommon('Proposal') : ''}
                  {getLanguage() === 'vi'
                    ? ' ' + (detail.sequencePolicy ?? 0)
                    : detail.sequencePolicy + '' + generateSTT(detail.sequencePolicy ?? '1')}
                  {detail.totalPolicy ? `/${detail.totalPolicy}` : ''}
                </Text>
              </View>
            ),
            colLength: '100%'
          }
        ]
        // @ts-ignore
        setGeneralFields(generalFields.concat(feeNotShare))
      }
    }
  }, [voiceRecord])
  // console.log(detail, 'sdsd')
  const formatPercent = (a: number, b: number) => {
    const result = Math.floor((a / b) * 100) || 0
    return result + '%'
  }

  const renderSpeech = () => {
    let stringLs: string[] = []

    if (speechAccents.finalWarningMatching) {
      stringLs.push(tCommon('TextWarningMatch'))
    }

    if (speechAccents.warningAgent) {
      stringLs.push(tCommon('TextWarningAgent'))
    }

    if (speechAccents.warningCustomer) {
      stringLs.push(tCommon('TextWarningCustomer'))
    }

    return stringLs
  }

  const overviewAI = React.useMemo(() => {
    let speectAccent = renderSpeech()
    return [
      {
        label: tCommon('TimeProcessingAI'),
        value: (
          <Text>
            {state.voiceDetail?.scoreSession?.processingDuration
              ? state.voiceDetail?.scoreSession.processingDuration +
                ' giây' +
                ` (khoảng ${controller.formatTime(+state.voiceDetail?.scoreSession.processingDuration)} phút)`
              : '-'}
          </Text>
        ),
        subValue: '',
        passed: ''
      },
      {
        label: tCommon('EmployeeKeyWord'),
        value: keyWordInfo.agentScoredPassed === keyWordInfo.maxAgentScore ? tCommon('Pass') : tCommon('Fail'),
        subValue: `(${keyWordInfo.agentScoredPassed || 0}/${keyWordInfo.maxAgentScore || 0}) ${formatPercent(
          keyWordInfo.agentScoredPassed || 0,
          keyWordInfo.maxAgentScore || 0
        )}`,
        passed: keyWordInfo.agentScoredPassed === keyWordInfo.maxAgentScore ? true : false
      },
      {
        label: tCommon('CustomerKeyword'),
        value: keyWordInfo.customerScoredPassed === keyWordInfo.maxCustomerScore ? tCommon('Pass') : tCommon('Fail'),
        subValue: `(${keyWordInfo.customerScoredPassed || 0}/${keyWordInfo.maxCustomerScore || 0}) ${formatPercent(
          keyWordInfo.customerScoredPassed || 0,
          keyWordInfo.maxCustomerScore || 0
        )}`,
        passed: keyWordInfo.customerScoredPassed === keyWordInfo.maxCustomerScore ? true : false
      },
      {
        label: tCommon('SpeechSeparationResult'),
        value: (
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            {speectAccent.map((item, index) => {
              return (
                <Text key={index} style={{ color: '#ED1B2D' }}>
                  {item}
                </Text>
              )
            })}
          </View>
        ),
        subValue: '',
        passed: false
      },
      {
        label: tCommon('AgencyKeywordValue'),
        value:
          keyWordGroupInfo.agentScoredPassed === keyWordGroupInfo.maxAgentScore ? tCommon('Pass') : tCommon('Fail'),
        subValue: `(${keyWordGroupInfo.agentScoredPassed || 0}/${keyWordGroupInfo.maxAgentScore || 0}) ${formatPercent(
          keyWordGroupInfo.agentScoredPassed || 0,
          keyWordGroupInfo.maxAgentScore || 0
        )}`,
        passed: keyWordGroupInfo.agentScoredPassed === keyWordGroupInfo.maxAgentScore ? true : false
      },
      {
        label: tCommon('CustomerKeywordValue'),
        value:
          keyWordGroupInfo.customerScoredPassed === keyWordGroupInfo.maxCustomerScore
            ? tCommon('Pass')
            : tCommon('Fail'),
        subValue: `(${keyWordGroupInfo.customerScoredPassed || 0}/${
          keyWordGroupInfo.maxCustomerScore || 0
        }) ${formatPercent(keyWordGroupInfo.customerScoredPassed || 0, keyWordGroupInfo.maxCustomerScore || 0)}`,
        passed: keyWordGroupInfo.customerScoredPassed === keyWordGroupInfo.maxCustomerScore ? true : false
      }
    ]
  }, [keyWordInfo, keyWordGroupInfo, speechAccents])

  const columns = [
    {
      key: '1',
      title: tCommon('File'),
      name: 'stt'
    },
    {
      key: '3',
      title: tCommon('Duration'),
      name: 'percent'
    },
    {
      key: '4',
      title: tCommon('Scenario'),
      name: 'relationShipCode'
    },
    {
      key: '5',
      title: tCommon('RecordDate'),
      name: 'relationShipCode'
    },
    {
      key: '6',
      title: tCommon('Gradingdate'),
      name: 'relationShipCode'
    },
    {
      key: '7',
      title: tCommon('RecordingMethod'),
      name: 'voiceMode'
    }
  ]

  const columnsVoiceQC = [
    {
      key: '1',
      title: tCommon('STT'),
      name: 'stt',
      filter: false,
      type: '',
      option: [],
      width: 100
    },
    {
      key: '2',
      title: tCommon('Speaker'),
      name: 'owner',
      filter: true,
      type: 'select',
      option: [
        {
          label: tCommon('All'),
          value: ''
        },
        {
          label: tCommon('Agent'),
          value: 'agent'
        },
        {
          label: tCommon('Customer'),
          value: 'customer'
        }
      ],
      width: 300
    },
    {
      key: '3',
      title: tCommon('KeyWord'),
      name: 'keyword',
      filter: true,
      type: 'input',
      option: [],
      width: 100
    },
    {
      key: '4',
      title: tCommon('KeywordValue'),
      name: 'worthKeyword',
      filter: true,
      type: 'input',
      option: [],
      width: 100
    },
    {
      key: '5',
      title: tCommon('ResultAI'),
      name: 'relationShipCode',
      filter: false,
      type: '',
      option: [],
      width: 100
    },
    {
      key: '6',
      title: tCommon('ConfidenceRate'),
      name: 'relationShipCode',
      filter: false,
      type: '',
      option: [],
      width: 100
    },
    {
      key: '7',
      title: tCommon('SystemRating'),
      name: 'relationShipCode',
      filter: false,
      type: '',
      option: [],
      width: 100
    },
    {
      key: '8',
      title: tCommon('Theshold'),
      name: 'relationShipCode',
      filter: false,
      type: '',
      option: [],
      width: 100
    },
    {
      key: '9',
      title: tCommon('TypingResult'),
      name: 'resultSystem',
      filter: true,
      type: 'select',
      option: [
        {
          label: tCommon('All'),
          value: ''
        },
        {
          label: tCommon('Pass'),
          value: 'pass'
        },
        {
          label: tCommon('Fail'),
          value: 'fail'
        }
      ],
      width: 100
    },
    {
      key: '10',
      title: tCommon('UserResult'),
      name: 'result',
      filter: true,
      type: 'select',
      option: [
        {
          label: tCommon('All'),
          value: ''
        },
        {
          label: tCommon('Pass'),
          value: 'pass'
        },
        {
          label: tCommon('Fail'),
          value: 'fail'
        }
      ],
      width: 100
    },
    {
      key: '11',
      title: tCommon('Reason'),
      name: 'relationShipCode',
      filter: false,
      type: '',
      option: [],
      width: 100
    }
  ]

  function getOwnerLabel(owner: string | null | undefined) {
    const ownerLabels: { [key: string]: string } = {
      customer: tCommon('Customer'),
      agent: tCommon('Agent')
    }
    return owner ? ownerLabels[owner] || owner : '-'
  }

  const renderLableObjectCode = (code: string) => {
    switch (code) {
      case 'TVV':
        return 'Tư vấn viên'
      case 'BMBH':
        return 'Khách hàng'
      case 'TVVBMBH':
        return 'Tư vấn viên - Khách hàng'
      case 'NDBH':
        return 'Người được bảo hiểm'
      case 'TVVNDBH':
        return 'Tư vấn viên - Người được bảo hiểm'
      case 'AG':
        return 'Agent'
      case 'LA':
        return 'Life Assured'
      case 'PO':
        return 'Policy Owner'
      case 'AGLA':
        return 'Agent - Life Assured'
      case 'AGPO':
        return 'Agent - Policy Owner'
      default:
        return ''
    }
  }
  useEffect(() => {
    if (voiceRecord.length > 0) {
      const getDetailVoice = () => {
        pipe(
          ZIO.zipPar(
            VoiceRecordingServices.getConfigTranscript(voiceRecord?.[0]?.scriptType ?? '', processId),
            VoiceRecordingServices.getVoiceDetail(processId, voiceRecord?.[0]?.filename ?? ''),
            ZIO.succeed(setLoadingDetail(true))
          ),
          ZIO.map(([config, res, _]) => {
            if (config?.body?.scripts?.length) {
              setLoadingDetail(false)
            }
            const transcriptSorted =
              config?.body?.scripts.sort((a, b) => {
                return (a?.order ?? 0) - (b.order ?? 0)
              }) ?? []

            controller.setDefaultConfigTrainscript(transcriptSorted)

            controller.setVoiceDetailData(res.body)
            let transcripts: any[] = []
            let keyword: any[] = []
            let keywordGroup: any[] = []
            let speechToText: any[] = []
            res.body?.scoreSession?.scripts?.map((item) => {
              item?.dynamicKeyword?.map((kword) => {
                const data = {
                  ...kword,
                  value: isArray(kword.value) ? kword.value?.[0].feeType : kword.value,
                  result: {
                    label: 'Pass',
                    value: 'pass'
                  },
                  scriptId: item.scriptId
                }
                let result = kword.passed ? tCommon('Pass') : tCommon('Fail')
                let value = kword.passed ? 'pass' : 'fail'
                if (kword.result !== null) {
                  result = kword.result !== null && kword.result === 'pass' ? tCommon('Pass') : tCommon('Fail')
                  value = kword.result !== null && kword.result === 'pass' ? 'pass' : 'fail'
                }

                const waringMatch = res.body.transcripts?.transcripts?.find((item) => item.scriptId === item.scriptId)
                const speechls = {
                  segmentId: kword.segmentId,
                  keywordId: kword.keywordId,
                  scriptId: item.scriptId,
                  owner: kword.owner ?? '-',
                  key: '-',
                  worthValue: kword.submissionValue,
                  value: isArray(kword.value)
                    ? kword.value?.[0].feeType ?? '-'
                    : kword.value && kword.value !== 'null'
                    ? kword.value
                    : '-',
                  result: {
                    label: result,
                    value: value
                  },
                  reason: kword.reason || '',
                  threshold: kword.threshold ? kword.threshold + '%' : '',
                  systemRating: kword.systemRating ? kword.systemRating + '%' : '',
                  cof: '',
                  warningMatching: waringMatch?.warningMatching ?? false,
                  isGroup: false,
                  passed: {
                    label: kword.passed ? tCommon('Pass') : tCommon('Fail'),
                    value: kword.passed ? 'pass' : 'fail'
                  },
                  isSaved: true,
                  isChangedData: false
                }
                keyword.push(data)
                speechToText.push(speechls)
              })

              item?.keywordGroupEvaluations?.map((group) => {
                const data = {
                  ...group,
                  result: {
                    label: group?.result || 'Pass',
                    value: group?.result || 'pass'
                  },
                  scriptId: item.scriptId
                }
                let result = group.autoScoringPassed ? tCommon('Pass') : tCommon('Fail')
                let value = group.autoScoringPassed ? 'pass' : 'fail'
                if (group.result !== null) {
                  result = group.result !== null && group.result === 'pass' ? tCommon('Pass') : tCommon('Fail')
                  value = group.result !== null && group.result === 'pass' ? 'pass' : 'fail'
                }
                const waringMatch = res.body.transcripts?.transcripts?.find((x) => x.scriptId === item.scriptId)

                const speechGr = {
                  segmentId: group.segmentId,
                  keywordId: group.keywordId,
                  scriptId: item.scriptId,
                  owner: group.owner,
                  key: group.keywordGroupName ?? '-',
                  worthValue: '-',
                  value: group.keywordValue && group.keywordValue !== 'null' ? group.keywordValue : '-',
                  result: {
                    label: result,
                    value: value
                  },
                  reason: group.reason || '',
                  threshold: '',
                  systemRating: '',
                  cof: group?.cof ? Math.floor(parseFloat(group?.cof ?? 0) * 100) + '%' : '',
                  warningMatching: waringMatch?.warningMatching ?? false,
                  isGroup: true,
                  passed: {
                    label: group.autoScoringPassed ? tCommon('Pass') : tCommon('Fail'),
                    value: group.autoScoringPassed ? 'pass' : 'fail'
                  },
                  isSaved: true,
                  isChangedData: false
                }
                keywordGroup.push(data)
                speechToText.push(speechGr)
              })
            })

            const agentkey = keywordGroup?.filter((item) => item.owner === 'agent')
            const agentKeyPass = agentkey.filter((item) => item.autoScoringPassed === true || item.reason === 'pass')

            const customerkey = keywordGroup?.filter((item) => item.owner === 'customer')
            const customerKeyPass = customerkey.filter(
              (item) => item.autoScoringPassed === true || item.reason === 'pass'
            )

            setKeyWordInfo((prev) => ({
              ...prev,
              agentScoredPassed: agentKeyPass.length,
              maxAgentScore: agentkey.length,
              customerScoredPassed: customerKeyPass.length,
              maxCustomerScore: customerkey.length
            }))

            const agentkeyGr = keyword?.filter((item) => item.owner === 'agent')
            const agentKeyGrPass = agentkeyGr.filter((item) => item.passed === true || item.reason === 'pass')

            const customerGrkey = keyword?.filter((item) => item.owner === 'customer')
            const customerKeyGrPass = customerGrkey.filter((item) => item.passed === true || item.reason === 'pass')

            setKeyWordGroupInfo((prev) => ({
              ...prev,
              agentScoredPassed: agentKeyGrPass.length,
              maxAgentScore: agentkeyGr.length,
              customerScoredPassed: customerKeyGrPass.length,
              maxCustomerScore: customerGrkey.length
            }))
            setSpeechAccents((prev) => ({
              ...prev,
              finalWarningMatching: res.body.transcripts?.finalWarningMatching ?? false,
              warningAgent: res.body.transcripts?.warningAgent ?? false,
              warningCustomer: res.body.transcripts?.warningCustomer ?? false
            }))

            if (isEmpty(transcripts) && transcriptSorted.length > 0) {
              transcriptSorted.map((item) => {
                transcripts.push({
                  ...item,
                  scriptId: item.scriptId,
                  passed: false,
                  warningMatching: false,
                  channel: '',
                  content: item.content,
                  objectCode: renderLableObjectCode(item?.objectCode ?? '')
                })
              })
            }

            res.body.transcripts?.transcripts !== null &&
              res.body.transcripts?.transcripts?.map(
                (
                  t_script: { scriptId: any; segments: any[]; warningMatching: boolean | null | undefined },
                  index: number
                ) => {
                  let isPassKeyWord = true
                  let kwList = (keyword ?? [])?.filter((item) => item.scriptId === t_script.scriptId)
                  if (kwList.length > 0) {
                    isPassKeyWord = kwList.every((scr: { passed: boolean }) => {
                      return scr.passed === true
                    })
                  }

                  let isPassKeyWordGroup = true
                  let kwGrList = (keywordGroup ?? [])?.filter((item) => item.scriptId === t_script.scriptId)
                  if (kwGrList.length > 0) {
                    isPassKeyWordGroup = kwGrList.every(
                      (scr: { autoScoringPassed: boolean }) => scr.autoScoringPassed === true
                    )
                  }
                  t_script.segments.map((se) => {
                    const scResult = {
                      ...se,
                      scriptId: t_script.scriptId,
                      passed: isPassKeyWord && isPassKeyWordGroup,
                      warningMatching: t_script.warningMatching ?? false,
                      // channel: se.channel === 'agent' ? 'Tư vấn viên' : 'Khách hàng',
                      channel: renderLableObjectCode(transcriptSorted?.[index]?.objectCode ?? ''),
                      content: transcriptSorted?.[index]?.content,
                      objectCode: renderLableObjectCode(transcriptSorted?.[index]?.objectCode ?? ''),
                      transcript: se.transcript || ''
                    }
                    const indexResult = transcripts.findIndex((iiResult) => iiResult.scriptId === t_script.scriptId)
                    transcripts[indexResult] = scResult
                  })
                }
              )

            controller.setTranscriptData(transcripts)
            setSpeechData(speechToText)
            setFilteredDataTable(speechToText)
            setValue('dynamicKeyword', speechToText)
            setDynamicKeywordData?.(speechToText)
          }),
          ErrorHandling.run()
        )
      }

      const getAudioFile = () => {
        pipe(
          ZIO.zip(
            ZIO.succeed(controller.setLoadingSrc(true)),
            VoiceRecordingServices.getAudio(voiceRecord?.[0]?.blob ?? '')
          ),
          ZIO.map(([__, audioInfo]) => {
            const url = URL.createObjectURL(audioInfo.blob)

            controller.setCurrentTrackVoice({
              src: url
            })
            refTrack.current?.onLoad?.()
            const audioPlayer = document.getElementById('audio_track')
            // @ts-ignore
            audioPlayer?.load()
            if (!isEmpty(url)) {
              controller.setLoadingSrc(false)
            }
          }),
          ErrorHandling.run()
        )
      }
      getDetailVoice()
      getAudioFile()
    }
  }, [voiceRecord])

  useEffect(() => {
    if (timeProgress >= state.duration) {
      controller.setIsPlaying(false)
    }
    timeEndProgress !== null
      ? timeProgress >= timeEndProgress
        ? (setTimeEndProgress(null), controller.setIsPlaying(false), setActiveSpeaker(null), setActiveScript(null))
        : ''
      : ''
  }, [timeProgress])

  const handleActiveScriptAudio = (index: number | null, time: number, end: number) => {
    if (audioRef && audioRef.current) {
      //@ts-ignore
      audioRef.current.currentTime = time
      if (!state.isPlaying) {
        controller.setIsPlaying(true)
      }
    }
    setTimeEndProgress(end + 1)
    setActiveScript(index)
  }

  const onUpdate = () => {
    const isCheck = speechData.every((item) => item.isSaved === true)
    const isValid = isCheck ? true : false

    if (!isValid) {
      return showToast(m('MS020061'), 'error')
    }

    const submitedData = speechData?.map((item) => ({
      scriptId: item.scriptId || '',
      keywordId: item.keywordId || '',
      result: item.result?.value || '',
      reason: item.reason || '',
      updateKeywordType: item.isGroup ? 'UPDATE_KEYWORD_TYPE_GROUP_EVALUATION' : 'UPDATE_KEYWORD_TYPE_DYNAMIC'
    }))
    const data = {
      resultList: submitedData,
      processInstanceId: processId
    }
    pipe(
      VoiceRecordingServices.submitAIreasonUser(data),
      ZIO.map((res) => {
        if (res.code === '200') {
          showToast('Successfully', 'success')
          setIsSavedDynamicKeyword?.(true)
          setSpeechData([
            ...speechData.map((item) => ({
              ...item,
              isChangedData: false
            }))
          ])
        }
      }),
      ZIO.unsafeRun({})
    )
  }

  const isShowedSaveButton = (): boolean => {
    return !isSuspend && !isQC && !isHistory && !isInquiry
  }
  const refPlayer = React.useRef(null)
  const flatListRef = React.useRef<FlatList>(null)

  const handleCheckScriptAudio = (scriptId: string) => {
    const index = state?.transcripts?.findIndex((item) => item.scriptId === scriptId)
    setTimeout(() => {
      if (flatListRef.current) {
        flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
        flatListRef.current.scrollToIndex({ animated: true, index: index ?? 0 })
      }

      handleActiveScriptAudio(
        index ?? 0,
        (state?.transcripts?.[index ?? 0]?.start ?? 0) / 1000,
        (state?.transcripts?.[index ?? 0]?.end ?? 0) / 1000
      )
    }, 1000)

    if (refPlayer.current) {
      //@ts-ignore
      refPlayer.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  const recordingTypeName = React.useMemo(() => {
    let name = ''
    if (detail.voiceRecordingType) {
      const data = VoiceRecordingConst.RecordingTypeConstList.find((x) => x.code === detail.voiceRecordingType)
      name = i18n.language === 'vi' ? data?.nameVi ?? '' : data?.nameEn ?? ''
    }
    return name
  }, [detail.voiceRecordingType, i18n.language])

  const renderFilterTable = (
    type: string,
    option: Array<{ label: string; value: string }>,
    name: 'owner' | 'keyword' | 'worthKeyword' | 'result' | 'resultSystem'
  ) => {
    switch (type) {
      case 'select':
        return (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectTable
                id={`select_${name}`}
                // @ts-ignore
                value={value ?? null}
                onChange={(val) => {
                  onChange(val)
                  handleFilter()
                }}
                errorMessage={''}
                options={option}
              />
            )}
          />
        )
      case 'input':
        return (
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <InputTable
                onChange={(val) => {
                  onChange(val)
                  handleFilter()
                }}
                onBlur={onBlur}
                maxLength={50}
                value={value || ''}
              />
            )}
          />
        )
      default:
        return ''
    }
  }

  function removeAccents(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const handleUpdateForm = (index: number, data: any) => {
    append({
      segmentId: data.segmentId,
      keywordId: data.keywordId,
      owner: data.owner,
      key: data.key,
      value: data.value,
      result: data.result,
      reason: data.reason ?? '',
      threshold: data.threshold ?? '',
      cof: data.cof,
      scriptId: data.scriptId,
      worthValue: data.worthValue,
      systemRating: data.systemRating,
      passed: data.passed,
      isSaved: data.isSaved
    })

    setValue(`dynamicKeyword.${index}.segmentId`, data.segmentId)
    setValue(`dynamicKeyword.${index}.keywordId`, data.keywordId)
    setValue(`dynamicKeyword.${index}.owner`, data.owner)
    setValue(`dynamicKeyword.${index}.key`, data.key)
    setValue(`dynamicKeyword.${index}.value`, data.value)
    setValue(`dynamicKeyword.${index}.result`, data.result)
    setValue(`dynamicKeyword.${index}.reason`, data.reason)
    setValue(`dynamicKeyword.${index}.threshold`, data.threshold)
    setValue(`dynamicKeyword.${index}.cof`, data.cof)
    setValue(`dynamicKeyword.${index}.scriptId`, data.scriptId)
    setValue(`dynamicKeyword.${index}.worthValue`, data.worthValue)
    setValue(`dynamicKeyword.${index}.systemRating`, data.systemRating)
    setValue(`dynamicKeyword.${index}.passed`, data.passed)
    setValue(`dynamicKeyword.${index}.isSaved`, data.isSaved)
  }

  const handleFilter = () => {
    let filteredData = speechData

    // @ts-ignore
    if (!isEmpty(watch('owner')) && watch('owner')?.value !== '') {
      filteredData = filteredData.filter((item) => {
        // @ts-ignore
        return item.owner === watch('owner')?.value
      })
    }

    if (!isEmpty(watch('keyword')) && watch('keyword') !== '') {
      filteredData = filteredData.filter((item) => {
        const nameWithoutAccents = removeAccents(watch('keyword')?.toLowerCase() ?? '')
        const searchNameLC = item?.key ? removeAccents(item?.key?.toLowerCase()) : ''
        return searchNameLC.includes(nameWithoutAccents)
      })
    }

    if (!isEmpty(watch('worthKeyword')) && watch('worthKeyword') !== '') {
      filteredData = filteredData.filter((item) => {
        const valueWithoutAccents = removeAccents(watch('worthKeyword')?.toLowerCase() ?? '')
        const searchValueLC = item?.worthValue ? removeAccents(item?.worthValue?.toLowerCase()) : ''
        return searchValueLC.includes(valueWithoutAccents)
      })
    }

    // @ts-ignore
    if (!isEmpty(watch('result')) && watch('result')?.value !== '') {
      filteredData = filteredData.filter((item) => {
        // @ts-ignore
        return item.result.value === watch('result')?.value
      })
    }
    // @ts-ignore
    if (!isEmpty(watch('resultSystem')) && watch('resultSystem')?.value !== '') {
      filteredData = filteredData.filter((item) => {
        // @ts-ignore
        return item.passed.value === watch('resultSystem')?.value
      })
    }
    // remove()
    // reset({ dynamicKeyword: filteredData })
    console.log(filteredData, 'filteredData')
    setFilteredDataTable(filteredData)
  }

  const [contextMenu, setContextMenu] = React.useState<{
    x: number
    y: number
  } | null>(null)

  const [activeContextMenu, setActiveContextMenu] = React.useState<number | null>(null)

  const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    event.preventDefault()
    // setContextMenu({
    //   x: event.clientX,
    //   y: event.clientY
    // })
    // setActiveContextMenu(index)
  }, [])

  const refMenu = useRef(null)
  useEffect(() => {
    const handleOutSideClick = () => {
      //@ts-ignore
      if (refMenu.current && !refMenu.current?.contains(event.target)) {
        setContextMenu(null)
        setActiveContextMenu(null)
      }
    }

    document.addEventListener('click', handleOutSideClick, true)

    return () => {
      document.removeEventListener('click', handleOutSideClick, true)
    }
  }, [])

  const [visible, setVisible] = useState<boolean>(false)
  const [content, setContent] = useState<string>('')
  const handleReport = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, transcript: string) => {
    event.stopPropagation()
    setVisible(true)
    setContent(transcript)
  }

  const modalActions: ModalAction[] = [
    {
      text: t('submission:Confirm'),
      type: 'filled',
      action: () => {
        setVisible(false)
      },
      disabled: false,
      loading: false,
      onlyWide: false,
      style: {}
    }
  ]

  useEffect(() => {
    setDynamicKeywordData?.(speechData)
  }, [speechData])

  return (
    <ScrollView style={{ flex: 1 }}>
      <Container loading={loading}>
        <Panel title={tCommon('GeneralInfo')}>
          <FieldList dataSource={generalFields ?? []} />
        </Panel>

        <Panel title={tCommon('RecordList')}>
          <View>
            <TableContainer component={Paper} style={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    {columns?.map((item, index) => {
                      return (
                        <TableCell className={classes.tableCellHeader} style={{ width: 100 }} key={index}>
                          {item.title}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(voiceRecord ?? []).map((item, index) => {
                    const length = voiceRecord.length
                    return (
                      <TableRow key={index} style={index !== 0 ? { backgroundColor: '#F9F9F9' } : {}}>
                        <TableCell className={classes.tableCell}>
                          <Pressable>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                              <SC.TextTable isDisable={index !== 0}>
                                {tCommon('File') + ' ' + (length - index) + ' '}
                              </SC.TextTable>
                              {index !== 0 ? '' : <assets.LoudSpeakerIcon />}
                            </View>
                          </Pressable>
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          <SC.TextTable isDisable={index !== 0}>
                            {item.duration ? formatDuration(item.duration) : ''}
                          </SC.TextTable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <SC.TextTable isDisable={index !== 0}>{item.scriptType || '-'}</SC.TextTable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <SC.TextTable isDisable={index !== 0}>
                            {PulseOpsFormat.dateStringtoFormat(item.recordDateTime, 'DD/MM/YYYY HH:mm:ss')}
                          </SC.TextTable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <SC.TextTable isDisable={index !== 0}>
                            {PulseOpsFormat.dateStringtoFormat(item.checkRecordDateTime, 'DD/MM/YYYY HH:mm:ss')}
                          </SC.TextTable>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <SC.TextTable isDisable={index !== 0}>
                            {item.voiceMode || ''}
                          </SC.TextTable>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </View>
        </Panel>
        {overviewAI.length > 0 && state.voiceDetail && (
          <View style={{ marginTop: 10, marginBottom: 10 }}>
            <View style={styles.sectionOverview1}>
              <View style={styles.row}>
                <Text style={[styles.sectionInfoHeaderTitle, { marginRight: 5 }]}>{tCommon('OverallAssessment')}</Text>
                <Text
                  style={[
                    styles.sectionInfoHeaderTitle,
                    { color: detail.voiceRecordingType === 'FAIL' ? '#ED1B2D' : '#1DC06F' }
                  ]}
                >
                  {recordingTypeName ? '- ' + recordingTypeName : ''}
                </Text>
              </View>
              <View style={[styles.row, { marginTop: 10 }]}>
                {overviewAI.map((item, index) => {
                  return (
                    <View style={styles.col3} key={index}>
                      <Text style={[styles.label]}>{item.label}</Text>
                      {item.subValue != '' ? (
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <SC.TextColor isPass={item.passed}>{item.value}</SC.TextColor>
                          <Text style={[styles.value, { marginLeft: 5 }]}>{' ' + item.subValue}</Text>
                        </View>
                      ) : (
                        <> {item.value}</>
                      )}
                    </View>
                  )
                })}
              </View>
            </View>
          </View>
        )}
        <View style={{ paddingVertical: 20, paddingHorizontal: 10 }}>
          <View style={styles.row}>
            <Text style={[styles.sectionInfoHeaderTitle, { marginBottom: 10 }]}>{tCommon('RecommnetDataSource')}</Text>
          </View>
          <TableContainer component={Paper} style={{ maxHeight: 800 }}>
            <Table stickyHeader>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {columnsVoiceQC?.map((item, index) => {
                    return (
                      <TableCell className={classes.tableCellHeader} style={{ width: item.width }} key={index}>
                        {item.title}
                        {item.filter === true ? (
                          <View style={{ width: 150 }}>
                            {
                              /*@ts-ignore*/
                              renderFilterTable(item.type, item.option, item.name)
                            }
                          </View>
                        ) : (
                          ''
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDataTable.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>{<Text>{index + 1}</Text>}</TableCell>
                      <TableCell className={classes.tableCell} style={{ width: 300 }}>
                        {
                          <Text style={[styles.cellNoWrap]}>
                            {
                              <View style={{ display: 'flex', flexDirection: 'row' }}>
                                <Text>{getOwnerLabel(item.owner)}</Text>
                                {item.warningMatching && (
                                  <LightTooltip
                                    title={tCommon('TextWarningMatch')}
                                    disableHoverListener={false}
                                    placement="bottom-start"
                                  >
                                    <Text style={{ marginLeft: 5, marginTop: 2 }}>
                                      <assets.NoticeRedIcon />
                                    </Text>
                                  </LightTooltip>
                                )}
                              </View>
                            }
                          </Text>
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.key !== null || item.key !== '' ? <Text>{item.key}</Text> : <Text>-</Text>}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.worthValue !== null || item.worthValue !== '' ? (
                          <Text>{item.worthValue}</Text>
                        ) : (
                          <Text>-</Text>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.value ? <Text>{item.value}</Text> : <Text>-</Text>}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Text style={{ textAlign: 'right' }}>
                          {item.cof !== '' ? <Text>{item.cof}</Text> : <Text>-</Text>}
                        </Text>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {item.systemRating !== '' ? (
                          <SC.TextColor isPass={item.result?.value === 'pass' ? true : false}>
                            {item.systemRating}
                          </SC.TextColor>
                        ) : (
                          <Text>-</Text>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Text style={{ textAlign: 'right' }}>
                          {item.threshold !== '' ? (
                            <SC.TextColor isPass={true}>{item.threshold}</SC.TextColor>
                          ) : (
                            <Text>-</Text>
                          )}
                        </Text>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <View style={{ width: 150, marginRight: 5 }}>
                            <SelectTable
                              id="decision"
                              value={item.passed ?? null}
                              disabled={true}
                              errorMessage={''}
                              options={VoiceDataGridForm.reasonOption.map((item) => ({
                                label: tCommon(`${item.label}`),
                                value: item.value
                              }))}
                            />
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              handleCheckScriptAudio(item.scriptId ?? '')
                              setActiveSpeaker(index)
                            }}
                            style={{ paddingLeft: 5 }}
                          >
                            {activeSpeaker !== null && index === activeSpeaker ? (
                              <assets.LoudSpeakerRedIcon />
                            ) : (
                              <assets.LoudSpeakerIcon />
                            )}
                          </TouchableOpacity>
                        </View>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <View style={{ width: 150, marginRight: 5 }}>
                            <SelectTable
                              id="decision"
                              value={item.result ?? null}
                              errorMessage={''}
                              options={VoiceDataGridForm.reasonOption.map((item) => ({
                                label: tCommon(`${item.label}`),
                                value: item.value
                              }))}
                              onChange={(e) => {
                                let data = [...speechData]
                                let dataFilter = [...filteredDataTable]
                                if (dataFilter[index]) {
                                  dataFilter[index].result = e
                                }
                                const idx = data.findIndex((it) => it.keywordId === item.keywordId)
                                if (idx != -1) {
                                  data[idx].result = e
                                }

                                if (dataFilter[index]?.passed?.value !== e?.value && dataFilter[index].reason === '') {
                                  data[idx].isSaved = false
                                  dataFilter[index].isSaved = false
                                } else {
                                  data[idx].isSaved = true
                                  dataFilter[index].isSaved = true
                                  if (dataFilter[index].reason !== '') {
                                    data[idx].reason = ''
                                    dataFilter[index].reason = ''
                                  }
                                }
                                data[idx].isChangedData = true
                                dataFilter[index].isChangedData = true
                                setIsSavedDynamicKeyword?.(false)
                                setSpeechData(data)
                                setFilteredDataTable(dataFilter)
                              }}
                            />
                          </View>
                          <TouchableOpacity
                            onPress={() => {
                              handleCheckScriptAudio(item.scriptId ?? '')
                              setActiveSpeaker(index)
                            }}
                            style={{ paddingLeft: 5 }}
                          >
                            {activeSpeaker !== null && index === activeSpeaker ? (
                              <assets.LoudSpeakerRedIcon />
                            ) : (
                              <assets.LoudSpeakerIcon />
                            )}
                          </TouchableOpacity>
                        </View>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <TextInput
                          style={[
                            {
                              borderColor: filteredDataTable?.[index]?.isSaved === false ? 'red' : '#D3DCE6',
                              borderWidth: 1,
                              height: 35,
                              fontSize: 15,
                              borderRadius: 8,
                              padding: 5,
                              backgroundColor: '#FFF'
                            }
                          ]}
                          onChangeText={(e) => {
                            let data = [...speechData]
                            let dataFilter = [...filteredDataTable]
                            if (dataFilter[index]) {
                              dataFilter[index].reason = e
                            }
                            const idx = data.findIndex((it) => it.keywordId === item.keywordId)
                            if (idx != -1) {
                              data[idx].reason = e
                            }

                            if (e !== '') {
                              dataFilter[index].isSaved = true
                              data[idx].isSaved = true
                            } else if (dataFilter[index]?.passed?.value !== dataFilter[index]?.result?.value) {
                              dataFilter[index].isSaved = false
                              data[idx].isSaved = false
                            }
                            data[idx].isChangedData = true
                            dataFilter[index].isChangedData = true
                            setIsSavedDynamicKeyword?.(false)
                            setSpeechData(data)
                            setFilteredDataTable(dataFilter)
                          }}
                          editable={isShowedSaveButton()}
                          maxLength={50}
                          value={item.reason ?? ''}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <View style={styles.col_1}>
            {isShowedSaveButton() && (
              <TouchableOpacity style={styles.inputSearch} onPress={() => onUpdate()}>
                <Text style={{ textAlign: 'center', color: '#fff', width: 75, fontWeight: '700' }}>{'Save'}</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
        {loadingDetail && <LinearProgress color="secondary" />}
        {state.transcripts && state.transcripts?.length > 0 && (
          <>
            <View style={[styles.sectionInfo, { backgroundColor: '#EEEEEE' }]}>
              <View style={styles.row}>
                <Text style={[styles.sectionInfoHeaderTitle, { marginBottom: 10 }]}>
                  {tCommon('VoiceRecordingDetail') + '-' + tCommon('File') + ' ' + voiceRecord.length}
                </Text>
              </View>
              <View>
                <View
                  style={{
                    marginTop: 10,
                    borderColor: '#CCCCCC',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 16,
                    overflow: 'hidden'
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <View
                      style={[
                        styles.sectionRecord,
                        {
                          backgroundColor: '#F4F4F4',
                          borderBottomColor: '#D3DCE6',
                          borderBottomWidth: 1,
                          borderStyle: 'solid'
                        }
                      ]}
                    >
                      <View style={styles.row}>
                        <Text style={[styles.sectionInfoHeaderTitle, { margin: 20 }]}>
                          {tCommon('SampleVoiceRecording')}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={[
                        styles.sectionRecord,
                        {
                          backgroundColor: '#F4F4F4',
                          borderBottomColor: '#D3DCE6',
                          borderBottomWidth: 1,
                          borderStyle: 'solid'
                        }
                      ]}
                    >
                      <Text style={[styles.sectionInfoHeaderTitle, { margin: 20 }]}>
                        {tCommon('SpeechToTextResult')}
                      </Text>
                    </View>
                  </View>
                  <FlatList
                    style={{
                      height: 520,
                      flexGrow: 0
                    }}
                    keyExtractor={(_item, index) => index.toString()}
                    ref={flatListRef}
                    data={state.transcripts}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row'
                          }}
                        >
                          <View
                            style={[
                              styles.sectionRecord,
                              {
                                backgroundColor: '#F4F4F4',
                                borderBottomColor: '#D3DCE6',
                                borderBottomWidth: 1,
                                borderStyle: 'solid'
                              }
                            ]}
                          >
                            <View style={{ marginTop: 15, marginBottom: 15 }}>
                              <View
                                style={{
                                  backgroundColor: '#EEEEEE',
                                  padding: 10,
                                  marginLeft: 10,
                                  marginRight: 10,
                                  flex: 1,
                                  borderRadius: 16
                                }}
                              >
                                <View style={{ display: 'flex', flex: 1, flexDirection: 'row', marginBottom: 10 }}>
                                  <Text style={[styles.sectionTitleContent, { paddingLeft: 0 }]}>
                                    Nội dung ghi âm của {item?.objectCode}
                                  </Text>
                                </View>
                                <View>
                                  <div className="scritpBox" dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                                </View>
                              </View>
                            </View>
                          </View>

                          <View
                            style={[
                              styles.sectionRecord,
                              {
                                backgroundColor: '#F4F4F4',
                                borderBottomColor: '#D3DCE6',
                                borderBottomWidth: 1,
                                borderStyle: 'solid'
                              }
                            ]}
                          >
                            {item?.transcript && (
                              <View style={{ marginTop: 15, marginBottom: 15 }}>
                                <div
                                  style={{
                                    backgroundColor:
                                      activeScript === index ? '#CCCCCC' : item?.passed ? '#D8F8E9' : '#FFD7DB',
                                    padding: 10,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    flex: 1,
                                    borderRadius: 16,
                                    borderColor: activeScript === index ? '#ED1B2E' : '#DDDDE3',
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    cursor: 'pointer',
                                    position: 'relative'
                                  }}
                                  onClick={() =>
                                    handleActiveScriptAudio(index, (item?.start || 0) / 1000, (item?.end || 0) / 1000)
                                  }
                                  onContextMenu={(e) => {
                                    handleContextMenu(e, index)
                                  }}
                                >
                                  <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                    <View
                                      style={[
                                        styles.sectionIcon,
                                        {
                                          borderColor: activeScript === index ? '#ED1B2E' : '#DDDDE3',
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center'
                                        }
                                      ]}
                                    >
                                      <View style={{ width: 18, height: 18 }}>
                                        {activeScript === index ? (
                                          <Image
                                            source={require('./icons8-sound.gif')}
                                            style={{ height: 17, paddingLeft: 1 }}
                                          />
                                        ) : (
                                          <assets.LoudSMIcon />
                                        )}
                                      </View>
                                      <Text
                                        style={{
                                          color: activeScript === index ? '#ED1B2E' : '#27272A',
                                          fontSize: 10,
                                          paddingHorizontal: 5
                                        }}
                                      >
                                        {activeScript === index
                                          ? controller.formatTime(timeProgress)
                                          : formatDuration(item?.start || 0)}
                                      </Text>
                                    </View>

                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                      <Text style={styles.sectionTitleContent}>Nội dung ghi âm của {item.channel}</Text>
                                      {item.warningMatching && (
                                        <LightTooltip
                                          title={tCommon('TextWarningMatch')}
                                          disableHoverListener={false}
                                          placement="bottom-start"
                                        >
                                          <Text style={{ marginLeft: 5, marginTop: 2 }}>
                                            <assets.NoticeRedIcon />
                                          </Text>
                                        </LightTooltip>
                                      )}
                                    </View>
                                  </View>
                                  <View>
                                    <div
                                      className="scritpBox"
                                      dangerouslySetInnerHTML={{ __html: item.transcript }}
                                    ></div>
                                    {/* <Text style={{ color: '#333333', fontWeight: '500' }}>{item.transcript}</Text> */}
                                  </View>
                                  {contextMenu && activeContextMenu === index && (
                                    <div
                                      id={`context-menu`}
                                      data-check={activeContextMenu === index}
                                      ref={refMenu}
                                      style={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        background: 'white',
                                        padding: 10,
                                        zIndex: 999,
                                        borderRadius: 16
                                      }}
                                      onClick={(e) => handleReport(e, item.transcript)}
                                    >
                                      <Text>Báo cáo</Text>
                                    </div>
                                  )}
                                </div>
                              </View>
                            )}
                          </View>
                        </View>
                      )
                    }}
                  />
                </View>
              </View>
            </View>
            <View
              // @ts-ignore
              ref={refPlayer}
              style={{
                marginTop: 10,
                borderRadius: 16,
                overflow: 'hidden'
              }}
            >
              <VoiceRecordConsumer
                shouldBuild={(pre, next) => pre.duration !== next.duration || pre.currentTrack !== next.currentTrack}
              >
                {() => {
                  return (
                    <DisplayTrack
                      ref={refTrack}
                      {...{
                        audioRef,
                        progressBarRef
                      }}
                    />
                  )
                }}
              </VoiceRecordConsumer>

              <VoiceRecordConsumer
                shouldBuild={(pre, next) => pre.duration !== next.duration || pre.currentTrack !== next.currentTrack}
              >
                {() => {
                  return (
                    <>
                      <div className="wrapper-audio">
                        <>
                          <Controls
                            {...{
                              audioRef,
                              progressBarRef,
                              setTimeProgress,
                              timeProgress
                            }}
                          />
                          <ProgressBar {...{ progressBarRef, audioRef, timeProgress }} />
                        </>
                      </div>
                    </>
                  )
                }}
              </VoiceRecordConsumer>
            </View>
          </>
        )}
      </Container>
      <ModalComponent
        title={'Báo cáo'}
        onClose={() => {
          setVisible(false)
        }}
        visible={visible}
        actions={modalActions}
        titleStyle={styles.modalTitle}
        contentStyle={styles.modalContent}
      >
        <View style={{ marginHorizontal: 20, marginTop: 20, maxHeight: height - 200 }}>
          <Input
            title={''}
            required={true}
            disabled={false}
            numberOfLines={5}
            value={content}
            multiline
            onChange={setContent}
          />
        </View>
      </ModalComponent>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  sectionStatus: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 16
  },
  sectionStatusTag: {
    backgroundColor: '#1EA5FC',
    borderRadius: 4,
    paddingVertical: 1,
    paddingHorizontal: 4,
    marginRight: 30
  },
  sectionStatusTagText: {
    color: '#FFFFFF',
    fontSize: 13,
    lineHeight: 17,
    fontWeight: 'bold'
  },
  dangerText: {
    color: '#ED1B2E',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  strongText: {
    fontSize: 15,
    lineHeight: 14,
    fontWeight: '600',
    marginLeft: 10
  },
  blueText: {
    color: '#1EA5FC',
    fontSize: 13,
    lineHeight: 14,
    marginRight: 30
  },
  sectionTrans: {
    // borderBottomRightRadius: 16,
    // borderBottomLeftRadius: 16,
    // borderColor: '#CCCCCC',
    // borderWidth: 1,
    // borderStyle: 'solid',
    // backgroundColor: '#FFFFFF',
    // padding: 10,
    height: 520,
    overflowY: 'scroll'
    // borderRadius: 16
  },
  sectionTrans1: {
    // backgroundColor: '#FFFFFF',
    // padding: 10,
    height: 460,
    overflowY: 'scroll'
    // borderRadius: 16
  },
  sectionIcon: {
    borderRadius: 16,
    // borderColor: '#DDDDE3',
    borderStyle: 'solid',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    width: 62,
    // textAlign: 'center',
    // color: '#27272A',
    fontSize: 10,
    padding: 4
  },
  sectionTitleContent: {
    color: '#333333',
    fontSize: 12,
    paddingLeft: 10,
    paddingTop: 2,
    fontWeight: '700'
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  col: {
    width: '48%',
    marginBottom: 10,
    paddingRight: 10
  },
  col3: {
    width: '33%',
    marginBottom: 10
  },
  sectionInfoHeaderTitle: {
    fontSize: 15,
    lineHeight: 22,
    fontWeight: '800',
    textTransform: 'uppercase'
  },
  label: {
    color: '#70777E',
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 8
  },
  value: {
    // color: '#000000',
    fontSize: 15,
    lineHeight: 22
  },
  sectionInfo: {
    width: '100%',
    padding: 30,
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#D3DCE6',
    borderRadius: 8,
    marginBottom: 16
  },
  sectionOverview: {
    padding: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    width: '49%',
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderWidth: 1
  },
  sectionOverview1: {
    padding: 24,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    borderColor: '#D3DCE6',
    borderStyle: 'solid',
    borderWidth: 1,
    width: '100%'
  },
  sectionRecord: {
    backgroundColor: '#FFFFFF',
    width: '50%'
  },
  cellNoWrap: {
    whiteSpace: 'nowrap'
  } as TextStyle,
  inputSearch: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    width: 140,
    height: 40,
    backgroundColor: '#ed1b2e',
    borderRadius: 100,
    padding: 10,
    boxShadow: 'none',
    marginTop: 10
  },
  col_1: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: 10,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalTitle: {
    textAlign: 'center'
  },
  modalContent: {
    backgroundColor: '#EEEEEE'
  },
  col12: {
    width: '100%',
    maxWidth: '100%'
  }
})
