import * as t from 'io-ts'
import { Maybe } from '@mxt/zio/codec'

export namespace VoiceRecordingModels {
  export const RecordC = t.array(
    t.type({
      recordDocument: Maybe(
        t.type({
          azureUrl: Maybe(t.string),
          fileSize: Maybe(t.string),
          totalRecordTime: Maybe(t.string),
          metadata: Maybe(
            t.array(
              t.type({
                scriptId: Maybe(t.string),
                timeStart: Maybe(t.string),
                timeEnd: Maybe(t.string)
              })
            )
          )
        })
      ),
      scripts: t.array(
        t.type({
          scriptId: Maybe(t.string),
          score: t.type({
            aiAgentScore: Maybe(t.number),
            maxAgentScore: Maybe(t.number),
            aiCustomerScore: Maybe(t.number),
            maxCustomerScore: Maybe(t.number)
          }),
          dynamicKeyword: t.array(
            t.type({
              key: Maybe(t.string),
              value: Maybe(t.string),
              segmentId: Maybe(t.string)
            })
          ),
          keywordGroupEvaluations: t.array(
            t.type({
              keywordGroupName: Maybe(t.string),
              keywordValue: Maybe(t.string),
              autoScoringPassed: Maybe(t.boolean),
              weight: Maybe(t.number),
              owner: Maybe(t.string),
              segmentId: Maybe(t.string),
              cof: Maybe(t.string)
            })
          )
        })
      )
    })
  )
  export const VoiceDataTypeC = t.type({
    body: t.type({
      generalInfo: Maybe(
        t.type({
          proposalNumber: Maybe(t.string),
          proposalStatus: Maybe(t.string),
          agentCode: Maybe(t.string),
          saleChannel: Maybe(t.string),
          officeCode: Maybe(t.string),
          recordDate: Maybe(t.string),
          recordDuration: Maybe(t.string),
          voiceCheckRuleAIResult: Maybe(t.string),
          autoVerification: Maybe(t.string),
          scriptType: Maybe(t.string),
          supplementInfoRequestDate: Maybe(t.string),
          caseSubmittedDate: Maybe(t.string),
          verificationDecision: Maybe(t.string)
        })
      ),
      records: Maybe(RecordC)
    })
  })
  export type RecordC = t.TypeOf<typeof RecordC>
  export type VoiceDataTypeC = t.TypeOf<typeof VoiceDataTypeC>

  const Body = t.type({
    filename: Maybe(t.string),
    azureUrl: Maybe(t.string),
    blob: Maybe(t.string),
    duration: Maybe(t.number),
    scriptType: Maybe(t.string),
    recordDateTime: Maybe(t.string),
    checkRecordDateTime: Maybe(t.string),
    voiceMode: Maybe(t.string)
  })

  const ResponseStatus = t.type({
    code: Maybe(t.number),
    message: Maybe(t.string)
  })

  export const VoiceListC = t.type({
    body: t.array(Body),
    responseStatus: ResponseStatus
  })
  export type ResponseVoiceList = t.TypeOf<typeof Body>
  export type VoiceListC = t.TypeOf<typeof VoiceListC>

  const DynamicKeyword = t.type({
    key: Maybe(t.string),
    segmentId: Maybe(t.string),
    value: Maybe(
      t.union([
        t.string,
        t.array(
          t.type({
            description: Maybe(t.string),
            feeType: Maybe(t.string)
          })
        ),
        t.number
      ])
    ),
    owner: Maybe(t.string),
    threshold: Maybe(t.number),
    order: Maybe(t.union([t.string, t.number])),
    passed: Maybe(t.boolean),
    keywordId: Maybe(t.string),
    result: Maybe(t.string),
    reason: Maybe(t.string),
    systemRating: Maybe(t.union([t.string, t.number])),
    submissionValue: Maybe(t.union([t.string, t.number])),
  })
  export type DynamicKeyword = t.TypeOf<typeof DynamicKeyword>

  const KeywordGroupEvaluations = t.type({
    autoScoringPassed: Maybe(t.boolean),
    cof: Maybe(t.string),
    keywordGroupName: Maybe(t.string),
    keywordValue: Maybe(t.string),
    owner: Maybe(t.string),
    segmentId: Maybe(t.string),
    weight: Maybe(t.number),
    result: Maybe(t.string),
    reason: Maybe(t.string),
    order: Maybe(t.union([t.string, t.number])),
    keywordId: Maybe(t.string)
  })

  export type KeywordGroupEvaluations = t.TypeOf<typeof KeywordGroupEvaluations>

  const VoiceRecordResponseStatus = t.type({
    code: Maybe(t.number),
    message: Maybe(t.string)
  })

  const Score = t.type({
    agentPassScore: Maybe(t.number),
    aiAgentScore: Maybe(t.number),
    aiAgentScorePassed: t.boolean,
    aiCustomerScore: Maybe(t.number),
    aiCustomerScorePassed: t.boolean,
    customerPassScore: Maybe(t.number),
    maxAgentScore: Maybe(t.number),
    maxCustomerScore: Maybe(t.number)
  })

  const Scripts = t.type({
    dynamicKeyword: Maybe(t.array(DynamicKeyword)),
    keywordGroupEvaluations: Maybe(t.array(KeywordGroupEvaluations)),
    score: Score,
    scriptId: Maybe(t.string)
  })

  const ScoreSession = t.type({
    callbackAt: Maybe(t.string),
    contractId: Maybe(t.string),
    message: Maybe(t.string),
    processingDuration: Maybe(t.string),
    score: Score,
    scripts: t.array(Scripts),
    sessionId: Maybe(t.string),
    statusCode: Maybe(t.number)
  })

  const Segments = t.type({
    channel: Maybe(t.string),
    end: Maybe(t.number),
    id: Maybe(t.string),
    start: Maybe(t.number),
    transcript: Maybe(t.string),
    scriptId: Maybe(t.string),
    passed: Maybe(t.boolean),
    warningMatching: Maybe(t.boolean),
    content: Maybe(t.string),
    objectCode: Maybe(t.string),
  })
  export type Segments = t.TypeOf<typeof Segments>

  const Transcripts1 = t.type({
    order: Maybe(t.number),
    scriptId: Maybe(t.string),
    segments: t.array(Segments),
    warningMatching: Maybe(t.boolean)
  })

  const Transcripts = t.type({
    status: t.string,
    transcripts: t.array(Transcripts1),
    finalWarningMatching: Maybe(t.boolean),
    warningAgent: Maybe(t.boolean),
    warningCustomer: Maybe(t.boolean)
  })

  export const VoiceRecordResponse = t.type({
    transcripts: Maybe(Transcripts),
    scoreSession: Maybe(ScoreSession)
  })
  export type VoiceRecordResponse = t.TypeOf<typeof VoiceRecordResponse>

  export const VoiceRecordDetailC = t.type({
    body: VoiceRecordResponse,
    responseStatus: VoiceRecordResponseStatus
  })

  export type VoiceRecordDetailC = t.TypeOf<typeof VoiceRecordDetailC>

  const ScriptsConfig = t.type({
    id: Maybe(t.string),
    scriptId: Maybe(t.string),
    objectCode: Maybe(t.string),
    scriptTypeCode: Maybe(t.string),
    active: Maybe(t.boolean),
    content: Maybe(t.string),
    time: Maybe(t.string),
    order: Maybe(t.number)
  })
  export type ScriptsConfig = t.TypeOf<typeof ScriptsConfig>

  const VoiceConfig = t.type({
    id: Maybe(t.string),
    source: Maybe(t.string),
    channel: Maybe(t.string),
    productType: Maybe(t.string),
    effFrom: Maybe(t.string),
    effTo: Maybe(t.string),
    clientType: Maybe(t.string),
    poRole: Maybe(t.string),
    laType: Maybe(t.string),
    scriptType: Maybe(t.string),
    scriptTypeCode: Maybe(t.string),
    poVoice: Maybe(t.string),
    laVoice: Maybe(t.string),
    scriptNumber: Maybe(t.string),
    vrcMandatoryPO: Maybe(t.string),
    vrcMandatoryLA: Maybe(t.string),
    aiCheck: Maybe(t.string),
    validFlag: Maybe(t.string),
    scenarioId: Maybe(t.string),
    version: Maybe(t.string)
  })

  const VoiceRecordingConfigTrainscript = t.type({
    scenarioId: Maybe(t.string),
    documentId: Maybe(t.string),
    version: Maybe(t.string),
    voiceConfig: Maybe(VoiceConfig),
    scripts: t.array(ScriptsConfig)
  })

  export const ConfigTrainscriptResponse = t.type({
    body: Maybe(VoiceRecordingConfigTrainscript),
    responseStatus: ResponseStatus
  })

  export type ConfigTrainscriptResponse = t.TypeOf<typeof ConfigTrainscriptResponse>

  const Errors = t.type({
    errorCode: Maybe(t.string),
    errorMessage: Maybe(t.string)
  })

  export const ReasonUserC = t.type({
    data: Maybe(t.type({})),
    code: Maybe(t.string),
    message: Maybe(t.string),
    messageVN: Maybe(t.string),
    errors: Maybe(t.array(Errors))
  })

  export type ReasonUserC = t.TypeOf<typeof ReasonUserC>

  const ResultList = t.type({
    scriptId: Maybe(t.string),
    result: Maybe(t.string),
    reason: Maybe(t.string),
    updateKeywordType: Maybe(t.string)
  })

  export const ResultListUserC = t.type({
    processInstanceId: Maybe(t.string),
    resultList: Maybe(t.array(ResultList)),
  })

  export type ResultListUserC = t.TypeOf<typeof ResultListUserC>

  export const DynamicKeywordC = t.type({
    keyword: Maybe(t.string),
    value: Maybe(t.string),
    labelEN: Maybe(t.string),
    labelVN: Maybe(t.string)
  })
  export type DynamicKeywordC = t.TypeOf<typeof DynamicKeywordC>

  export const DynamicKeywordResponseC = t.type({
    body: Maybe(t.array(DynamicKeywordC)),
    responseStatus: ResponseStatus
  })

  export type DynamicKeywordResponseC = t.TypeOf<typeof DynamicKeywordResponseC>

  export const ApplicationInfoC = t.type({
    processInstanceId: Maybe(t.string),
    p4oProcessInstanceId: Maybe(t.string),
    idcProcessInstanceId: Maybe(t.string),
    agentCode: Maybe(t.string),
    agentName: Maybe(t.string),
    channel: Maybe(t.string),
    gender: Maybe(t.string),
    idNumber: Maybe(t.string),
    laAddress: Maybe(t.string),
    laDob: Maybe(t.string),
    laFullName: Maybe(t.string),
    laPhoneNumber: Maybe(t.string),
    laType: Maybe(t.string),
    poAddress: Maybe(t.string),
    poDob: Maybe(t.string),
    poFullName: Maybe(t.string),
    poPhoneNumber: Maybe(t.string),
    poRole: Maybe(t.string),
    poType: Maybe(t.string),
    scriptType: Maybe(t.string),
    premiumAmount: Maybe(t.string),
    premiumFrequency: Maybe(t.string),
    productCode: Maybe(t.string),
    productName: Maybe(t.string),
    proposalNumber: Maybe(t.string),
    sumAssured: Maybe(t.string),
    businessKey: Maybe(t.string),
    policyNumber: Maybe(t.string),
    countRecheck: Maybe(t.string),
    gaOffice: Maybe(t.string),
    officeCode: Maybe(t.string),
    coveredEmployee: Maybe(t.string),
    companyPhone: Maybe(t.string),
    companyAddress: Maybe(t.string),
    representationName: Maybe(t.string),
    bankCode: Maybe(t.string),
    bankName: Maybe(t.string),
    numberOfInsured: Maybe(t.string),
    representCorp: Maybe(t.string),
    employeeNumber: Maybe(t.string),
    agentLicense: Maybe(t.string),
    employeeAmount: Maybe(t.number),
    preferenceName: Maybe(t.string),
    corporateName: Maybe(t.string),
    poDoB: Maybe(t.string),
    planedPremiumTerm: Maybe(t.string),
    clientNumber: Maybe(t.string)
  })

  export type ApplicationInfoC = t.TypeOf<typeof ApplicationInfoC>
  export const ApplicationInfoResponseC = t.type({
    body: ApplicationInfoC,
    responseStatus: ResponseStatus
  })
  export type ApplicationInfoResponseC = t.TypeOf<typeof ApplicationInfoResponseC>
}
